@import '../../../styles/globalVariables';

.brand {
  display: inline-grid;
  justify-content: center;
  justify-self: center;
  line-height: 0px;

  &.sticky {
    background-image: url('/images/wasalt-logo-ar.svg');
  }

  @include for-size(tablet-up) {
    order: 1;
  }
}

.logoDiv{
  width: auto;
  max-width: 100%;
  height: 40px;

  @media screen and (max-width: 979px) {
    width: auto;
    height: 32px;
  }
  .logo {
    max-width: 100%;
    height: 100%;
    width: auto;
  }
}

